import React from "react";
import { Container, Modal } from "react-bootstrap";
import PrivacyPolicyData from "../../Data/PrivacyPolicy.json";

function PrivacyPolicyModal(props) {
  const CONTENT = PrivacyPolicyData,
    { show, onHide } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--privacy"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          {CONTENT[0]?.title && <h2>{CONTENT[0].title}</h2>}
          {CONTENT[0]?.text &&
            CONTENT[0].text.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default PrivacyPolicyModal;
