import React from "react";
import { motion } from "framer-motion";

function Transition({ children }) {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.8,
  };

  const pageStyle = {
    position: "relative",
  };

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="visible"
      exit="hidden"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
}

export default Transition;
