import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./App.scss";
import StoryPages from "./Components/Pages/StoryPages";
import HomePage from "./Components/Pages/HomePage";
import ResourcePage from "./Components/Pages/ResourcePage";
import Footer from "./Components/Footer/Footer";
import CookieConsentBanner from "./Components/Utilities/CookieConsentBanner/CookieConsentBanner";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" render={() => <HomePage />} />
          <Route exact path="/L1" component={ResourcePage} />
          <Route exact path="/:id" component={StoryPages} />
        </Switch>
      </AnimatePresence>
      <CookieConsentBanner />
      <Footer />
    </div>
  );
}

export default App;
