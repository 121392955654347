import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { Container, Modal, Tab, Nav } from "react-bootstrap";
import Audio from "../Utilities/Audio/Audio";
import Video from "../Utilities/Video/Video";
import Image from "../Utilities/Image/Image";
import ReactCardFlip from 'react-card-flip';
import { ReactComponent as IconPdf } from "../../Assets/Images/Icons/icon-pdf.svg";
import Magnifier from "react-magnifier";

function ArtefactModal(props) {
  const { artefact, show, onHide } = props;
  const location = useLocation();

  const [isFlipped, setIsFlipped] = useState(false)

  const handleClick = (e) => {
    setIsFlipped(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  let switchTextToImage = false;
  if (location.pathname === '/C12' || location.pathname === '/C13' || location.pathname === '/C22')
    switchTextToImage = true;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--artefact"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey={artefact.artefactimage ? 'image' : artefact.artefactimage2 ? 'image2' : artefact.artefactimage3 ? 'image3' : artefact.artefacttitle ? 'text' : artefact.artefactpdf ? 'text2' : artefact.artefacttextorg ? 'text3' : artefact.artefactmp3 ? 'music' : artefact.artefactmp32 ? 'music2' : artefact.artefactmp4 ? 'video' : artefact.artefactmp42 ? 'video' : artefact.artefactmp43 ? 'video' : artefact.artefactiframe ? '3d' : 'image'}>
            <Nav variant="pills" className="flex-row modal--artefact__type-wrapper">
              {artefact?.artefactimage && (
                <Nav.Item>
                  <Nav.Link eventKey="image" className="modal--artefact__type-image">Image</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactimage2 && (
                <Nav.Item>
                  <Nav.Link eventKey="image2" className="modal--artefact__type-image">Image</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactimage3 && (
                <Nav.Item>
                  <Nav.Link eventKey="image3" className="modal--artefact__type-image">Image</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefacttitle && (
                <Nav.Item>
                  <Nav.Link eventKey="text" className="modal--artefact__type-text">Text</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactpdf && (
                <Nav.Item>
                  <Nav.Link eventKey="text2" className="modal--artefact__type-text">Text</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefacttextorg && (
                <Nav.Item>
                  {switchTextToImage ? <Nav.Link eventKey="image4" className="modal--artefact__type-image">Image</Nav.Link> : <Nav.Link eventKey="text3" className="modal--artefact__type-text">Text</Nav.Link>}
                </Nav.Item>
              )}
              {artefact?.artefactmp3 && (
                <Nav.Item>
                  <Nav.Link eventKey="music" className="modal--artefact__type-audio">Music</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactmp32 && (
                <Nav.Item>
                  <Nav.Link eventKey="music2" className="modal--artefact__type-audio">Music</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactmp4 && (
                <Nav.Item>
                  <Nav.Link eventKey="video" className="modal--artefact__type-video">Video</Nav.Link>
                </Nav.Item>
              )}
              {artefact?.artefactmp42 && (
                  <Nav.Item>
                    <Nav.Link eventKey="video2" className="modal--artefact__type-video">Video</Nav.Link>
                  </Nav.Item>
              )}
              {artefact?.artefactmp43 && (
                  <Nav.Item>
                    <Nav.Link eventKey="video3" className="modal--artefact__type-video">Video</Nav.Link>
                  </Nav.Item>
              )}
              {artefact?.artefactiframe && (
                <Nav.Item>
                  <Nav.Link eventKey="3d" className="modal--artefact__type-3d">3d</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              {artefact?.artefactimage && (
                <Tab.Pane eventKey="image">
                  <div className="modal__image modal__content-wrapper">
                    <Image
                        imageSrc={artefact.artefactimage}
                        imageAlt={artefact.artefactimagealt}
                        imageInfo={artefact.artefactimageinfo}
                    />
                  </div>
                </Tab.Pane>
              )}

              {artefact?.artefactimage2 && (
                  <Tab.Pane eventKey="image2">
                    <div className="modal__image modal__content-wrapper">
                      <Image
                          imageSrc={artefact.artefactimage2}
                          imageAlt={artefact.artefactimagealt2}
                          imageInfo={artefact.artefactimageinfo2}
                      />
                    </div>
                  </Tab.Pane>
              )}

              {artefact?.artefactimage3 && (
                  <Tab.Pane eventKey="image3">
                    <div className="modal__image modal__content-wrapper">
                      <Image
                          imageSrc={artefact.artefactimage3}
                          imageAlt={artefact.artefactimagealt3}
                          imageInfo={artefact.artefactimageinfo3}
                      />
                    </div>
                  </Tab.Pane>
              )}

              {artefact?.artefacttitle && (
                <Tab.Pane eventKey="text">
                  <div className="modal__title">
                    <h2 className="modal__title__field">{artefact.artefacttitle}</h2>
                  </div>
                  {artefact?.artefacttext && (
                    <div className="modal__text modal__content-wrapper">
                      <p className="description-text">{artefact.artefacttext}</p>
                    </div>
                  )}
                </Tab.Pane>
              )}

              {artefact?.artefactpdf && (
                <Tab.Pane eventKey="text2">
                  <div className="modal__pdf modal__content-wrapper">
                    <div className="modal__pdf-icon">
                      <IconPdf />
                    </div>
                    <div className="modal__pdf-content">
                      {artefact?.artefactpdftitle && (
                            <h3 className="modal__pdf-title">
                                {artefact.artefactpdftitle}
                              </h3>
                        )}
                      {artefact?.artefactpdftext && (
                            <p className="modal__pdf-text">{artefact.artefactpdftext}</p>
                        )}
                      <a
                          href={artefact.artefactpdf}
                          download
                          className="btn btn-primary"
                      >
                        Download PDF
                      </a>
                    </div>
                  </div>
                </Tab.Pane>
              )}

              {artefact?.artefacttextorg && (
                  <Tab.Pane eventKey={switchTextToImage ? "image4" : "text3"}>
                    <div className="modal__image modal__content-wrapper">
                      <div className={artefact.artefacttextlabel1 === 'Middle English' ? 'toggle-switch' : 'toggle-switch small-switch'}>
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleLanguage"
                            id="toggleLanguage"
                        />
                        <label className="toggle-switch-label" htmlFor="toggleLanguage" onClick={handleClick}>
                          <span className="toggle-switch-inner" data-content-original={artefact.artefacttextlabel1} data-content-translated={artefact.artefacttextlabel2}/>
                          <span className="toggle-switch-switch" />
                        </label>
                      </div>
                      <ReactCardFlip isFlipped={isFlipped.isFlipped} flipDirection="horizontal">
                        <div>
                          <Magnifier
                              src={artefact.artefacttextorg}
                              alt=""
                              zoomFactor={2}
                              mgWidth={200}
                              mgHeight={200}
                          />
                        </div>
                        <div>
                          <Magnifier
                              src={artefact.artefacttexttrans}
                              alt=""
                              zoomFactor={2}
                              mgWidth={200}
                              mgHeight={200}
                          />
                        </div>
                      </ReactCardFlip>
                      {artefact.artefacttextcaption && (
                          <p className="caption-text">{artefact.artefacttextcaption}</p>
                      )}
                      {artefact.artefacttextinfo && (
                        <div>
                          {!artefact.artefacttextinfo.map ? (
                            <p className="description-text" dangerouslySetInnerHTML={{ __html: artefact.artefacttextinfo }}></p>
                          ) : (
                            artefact.artefacttextinfo.map((paragraph, i) => (
                              <p className="description-text" key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
              )}

              {artefact?.artefactmp3 && (
                <Tab.Pane eventKey="music">
                  <div className="modal__audio modal__content-wrapper">
                    <Audio
                      audioSrc={artefact.artefactmp3}
                      audioInfo={artefact.artefactmp3info}
                      audioDesc={artefact.artefactmp3desc}
                    />
                  </div>
                </Tab.Pane>
              )}

              {artefact?.artefactmp32 && (
                <Tab.Pane eventKey="music2">
                  <div className="modal__audio modal__content-wrapper">
                    <Audio
                      audioSrc={artefact.artefactmp32}
                      audioInfo={artefact.artefactmp3info2}
                      audioDesc={artefact.artefactmp3desc2}
                    />
                  </div>
                </Tab.Pane>
              )}

              {artefact?.artefactmp4 && (
                <Tab.Pane eventKey="video">
                  <div>
                    <div className="modal__video modal__content-wrapper">
                      <Video
                          videoSrc={artefact.artefactmp4}
                          videoInfo={artefact.artefactmp4info}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              )}

              {artefact?.artefactmp42 && (
                  <Tab.Pane eventKey="video2">
                    <div>
                      <div className="modal__video modal__content-wrapper">
                        <Video
                            videoSrc={artefact.artefactmp42}
                            videoInfo={artefact.artefactmp4info2}
                        />
                      </div>
                    </div>
                  </Tab.Pane>
              )}

              {artefact?.artefactmp43 && (
                  <Tab.Pane eventKey="video3">
                    <div>
                      <div className="modal__video modal__content-wrapper">
                        <Video
                            videoSrc={artefact.artefactmp43}
                            videoInfo={artefact.artefactmp4info3}
                        />
                      </div>
                    </div>
                  </Tab.Pane>
              )}

              {artefact?.artefactiframe && (
                <Tab.Pane eventKey="3d">
                  <div>
                    <div className="modal__iframe modal__content-wrapper">
                      <div
                          dangerouslySetInnerHTML={{ __html: artefact.artefactiframe }}
                      />
                      {artefact?.artefactiframeinfo && (
                          <p className="description-text" dangerouslySetInnerHTML={{ __html: artefact.artefactiframeinfo }}></p>
                      )}
                    </div>
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </Container>
        <Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ArtefactModal;
