import React from "react";
import { Button } from "react-bootstrap";

function ButtonExternal(props) {
  const { classes, buttonlink, buttonlabel } = props;
  return (
    <Button
      className={`${"btn btn-external btn-secondary"} ${classes}`}
      href={buttonlink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {buttonlabel}
    </Button>
  );
}

export default ButtonExternal;
