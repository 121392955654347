import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { ReactComponent as Logo } from "../../Assets/Images/Icons/site-logo--black.svg";
import PrivacyPolicyModal from "../Modal/PrivacyPolicyModal";

function Footer() {
  const currentYear = new Date().getFullYear(),
    [modalShow, setModalShow] = useState(false);

  return (
    <footer>
      <Container>
        <a href="/" target="_self" aria-label="Back to the home page">
          <Logo />
        </a>
        <div className="copyright">
          <Button
            onClick={() => setModalShow(true)}
            variant="link"
            aria-label="Button to open the Privacy Policy modal"
          >
            Privacy Policy
          </Button>
          <PrivacyPolicyModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <p className="small">
            &copy;{" " + currentYear + " Canterbury Cathedral. All Rights Reserved"}
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
