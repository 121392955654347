import React from "react";

function Video(props) {
  const { videoSrc, videoInfo } = props;
  return (
    <div className="video-component">
      <iframe width="100%" height="100%" src={videoSrc} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      {videoInfo && (
        <div>
          {!videoInfo.map ? (
            <p dangerouslySetInnerHTML={{ __html: videoInfo }}></p>
          ) : (
            videoInfo.map((paragraph, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default Video;
