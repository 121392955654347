import React from "react";
import { Container } from "react-bootstrap";
import ButtonPrimary from "../../Components/Utilities/Buttons/ButtonPrimary";
import HomeData from "../../Data/Home.json";
import { ReactComponent as Logo } from "../../Assets/Images/Icons/site-logo.svg";
import Transition from "../Utilities/Transition/Transition";

function HomePage() {
  const CONTENT = HomeData,
    id = CONTENT[0].id,
    foregroundImage = CONTENT[0].image1,
    midgroundImage = CONTENT[0].image2,
    backgroundImage = CONTENT[0].image3,
    title = CONTENT[0].title,
    text = CONTENT[0].text,
    ctalabel1 = CONTENT[0].ctalabel1,
    ctatarget1 = CONTENT[0].ctatarget1,
    ctalabel2 = CONTENT[0].ctalabel2,
    ctatarget2 = CONTENT[0].ctatarget2;

  const BackgroundStyles = {
      width: "100%",
      height: "100vh",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    Foreground = {
      backgroundImage: `url(/images/${foregroundImage})`,
      backgroundSize: "contain",
      backgroundPosition: "bottom 50px center",
      zIndex: 2,
    },
    Midground = {
      backgroundImage: `url(/images/${midgroundImage})`,
      zIndex: 1,
    },
    Background = {
      backgroundImage: `url(/images/${backgroundImage})`,
      zIndex: 0,
    };

  return (
    <section className="homepage" id={id}>
      <Transition>
        <Container className="homepage__main-container">
          <div className="homepage__content">
            <div className="homepage__content-header">
              <div className="site-logo">
                <Logo />
              </div>
              {title && <h1 className="homepage__title">{title}</h1>}
            </div>
            {text && (
              <div className="homepage__text-wrapper">
                {!text.map ? (
                  <p className="homepage__text">{text}</p>
                ) : (
                  text.map((paragraph, i) => (
                    <p className="homepage__text" key={i}>
                      {paragraph}
                    </p>
                  ))
                )}
              </div>
            )}
            <div className="homepage__buttons">
              {ctatarget1 && (
                <ButtonPrimary
                  buttonlink={ctatarget1}
                  buttonlabel={ctalabel1}
                  classes="btn-light"
                />
              )}
              {ctatarget2 && (
                <ButtonPrimary
                  buttonlink={ctatarget2}
                  buttonlabel={ctalabel2}
                  classes="btn-light"
                />
              )}
            </div>
          </div>
          <div className="homepage__background-images">
            <div
                className="homepage__background-image homepage__foreground-layer"
                style={{ ...BackgroundStyles, ...Foreground }}
            ></div>
            <div
                className="homepage__background-image homepage__midground-layer"
                style={{ ...BackgroundStyles, ...Midground }}
            ></div>
            <div
                className="homepage__background-image homepage__background-layer"
                style={{ ...BackgroundStyles, ...Background }}
            ></div>
          </div>
        </Container>
      </Transition>
    </section>
  );
}

export default HomePage;
