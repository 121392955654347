import React from "react";
import { Container } from "react-bootstrap";
import ButtonPrimary from "../Utilities/Buttons/ButtonPrimary";
import IconNextPage from "../../Assets/Images/LearningResourceIcons/icon-next-page.svg";
import IconAudio from "../../Assets/Images/LearningResourceIcons/icon-audio.svg";
import IconMagnifyingGlass from "../../Assets/Images/LearningResourceIcons/icon-magnifying-glass.svg";
import IconArtefactImage from "../../Assets/Images/LearningResourceIcons/icon-artefact-image.svg";
import IconArtefactAudio from "../../Assets/Images/LearningResourceIcons/icon-artefact-audio.svg";
import IconArtefactVideo from "../../Assets/Images/LearningResourceIcons/icon-artefact-video.svg";
import IconArtefactText from "../../Assets/Images/LearningResourceIcons/icon-artefact-text.svg";
import IconArtefact3d from "../../Assets/Images/LearningResourceIcons/icon-artefact-3d.svg";
import Transition from "../Utilities/Transition/Transition";
import ButtonExternal from "../Utilities/Buttons/ButtonExternal";

function ResourcePage() {
  return (
    <div className="resource-page">
      <Container>
        <Transition>
          <h1 className="resource-page__title">Learning Resource</h1>
          <p className="resource-page__text">
            The digital pilgrimage experience provides an immersive and interactive experience for adults and children alike, exploring pilgrimage from early medieval times right up to the present day.
          </p>
          <p className="resource-page__text">
            It is intended to help participants develop their understanding of the motivations for pilgrimage to Canterbury Cathedral. The resource also enables you to find out what pilgrims in the past may have experienced both during their travels and once they arrived at their destination.
          </p>
          <p className="resource-page__text">
            This resource is designed to complement the Online learning resources available on the learning pages of the Cathedral website <a href="https://learning.canterbury-cathedral.org/pilgrimage/" className="black-text" target="_blank" rel="noopener noreferrer">https://learning.canterbury-cathedral.org/pilgrimage/</a>
          </p>
          <p className="resource-page__text">
            The online learning resources provide a programme of suggested lesson plans and activities together with curriculum links.
          </p>
          <p className="resource-page__text">
            <strong>As a teacher it is recommended that you familiarise yourself fully with <u>all</u> these resources before introducing them to your class as some sensitive themes and issues are explored.</strong>
          </p>

          <h5>How to navigate the digital pilgrimage experience</h5>
          <p className="resource-page__text">
            The pilgrimage resource is in essence an online story book. Users navigate their way through the story by answering questions; options for answering the questions will be highlighted in a blue box at the bottom of the page. Not every page asks users to make choices. Where there are no choices to make users will be asked to click on the blue box that says ‘next page’.
          </p>

          <img src={IconNextPage} alt="next page" />

          <p className="resource-page__text">
            All pages of the resource offer users a chance to either read the text or, if they would prefer hear the text read out. You can listen to the text by pressing the audio button on the top right of each page.
          </p>

          <img src={IconAudio} alt="audio" />

          <p className="resource-page__text">
            Some pages offer users the chance to learn about particular artefacts associated with the story. These may be images from stained glass windows, text extracts from medieval stories, photographs of objects, 3-D models or explanatory films. You can find these artefacts by pressing the magnifying glass button located to the bottom right of the illustration.
          </p>

          <img src={IconMagnifyingGlass} alt="magnifying glass" />

          <p className="resource-page__text">
            A list of artefact options will appear in a pop out box.
          </p>

          <img src={IconArtefactImage} alt="artefact icon" />
          <img src={IconArtefactAudio} alt="artefact audio icon" />
          <img src={IconArtefactVideo} alt="artefact video icon" />
          <img src={IconArtefactText} alt="artefact text icon" />
          <img src={IconArtefact3d} alt="artefact 3d icon" />

          <p className="resource-page__text">
            Beneath each artefact you will find a short text description.
          </p>
          <p className="resource-page__text">
            If you hover over the image artefacts you are able to magnify details.
          </p>
          <p className="resource-page__text">
            The following teachers’ notes will provide a more detailed description of each artefact. Follow the link given for each artefact and this will take you to the page of the digital pilgrimage experience on which the relevant artefact appears.
          </p>
          <p className="resource-page__text">
            Once users have followed a pilgrim to the end of their story, they will be offered the chance to follow a different pilgrim, clicking a button to return to the home page.
          </p>
          <p className="resource-page__text">
            For more detail about medieval pilgrimage and about each artefact view the ‘supporting information’ pdf, which is designed to provide teachers with a useful overview and user notes, making the DPE suitable to use in a classroom situation. The pdf contains further information about each artefacts and some additional artefacts and links to explain each pilgrim’s story.
          </p>
          <ButtonExternal buttonlink="/artefact/pdf/Supporting-Information.pdf" buttonlabel="Download PDF" classes="download-pdf"/>


          <h5>Acknowledgements</h5>
          <p className="resource-page__text">
            This resource was created with the generous support of the following external organisations and individuals.
          </p>
          <ul>
            <li><a href="https://thebecketstory.org.uk/pilgrimage" className="black-text" target="_blank" rel="noopener noreferrer">https://thebecketstory.org.uk/pilgrimage</a></li>
            <li>Canterbury Museums and Galleries - <a href="https://canterburymuseums.co.uk/" className="black-text" target="_blank" rel="noopener noreferrer">https://canterburymuseums.co.uk/</a></li>
            <li>Eastbridge Hospital - <a href="https://www.eastbridgehospital.org.uk/" className="black-text" target="_blank" rel="noopener noreferrer">https://www.eastbridgehospital.org.uk/</a></li>
            <li>Dr Katherine Emery</li>
            <li>Professor Rachel Koopmans, Associate Professor, Department of History, University of York, Toronto</li>
            <li>Matthew O’Keefe</li>
            <li>Rough Musicke - <a href="http://www.roughmusicke.com/" className="black-text" target="_blank" rel="noopener noreferrer">http://www.roughmusicke.com/</a></li>
            <li>Adrian Smith</li>
            <li>Rory Walker - Illustrator</li>
          </ul>
          <div className="middle-button-wrapper">
            <ButtonPrimary buttonlink="/" buttonlabel="Back to home"/>
          </div>
        </Transition>
      </Container>
    </div>
  );
}

export default ResourcePage;
