import React from "react";
import { Link } from "react-router-dom";

function ButtonPrimary(props) {
  const { classes, buttonlink, buttonlabel } = props;
  return (
    <Link to={buttonlink} className={`${"btn btn-primary"} ${classes}`}>
      {buttonlabel}
    </Link>
  );
}

export default ButtonPrimary;
