import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StoryData from "../../Data/Pages.json";
import ButtonPrimary from "../Utilities/Buttons/ButtonPrimary";
import { ReactComponent as IconMagnify } from "../../Assets/Images/Icons/icon-magnifying-glass.svg";
import Error404 from "../../Assets/Images/error-page.png";
import ArtefactModal from "../Modal/ArtefactModal";
import Audio from "../Utilities/Audio/Audio";
import useWindowWidth from "../Utilities/WindowWidth";
import Transition from "../Utilities/Transition/Transition";

function StoryPages() {
  const [id] = useState(useParams().id),
    [url, setUrl] = useState(""),
    [page, setPage] = useState(null),
    [artefact, setArtefact] = useState(),
    [modalShow, setModalShow] = useState(false),
    width = useWindowWidth(),
    [loaded, setLoaded] = useState(false);

  useEffect(() => {
    for (let i = 0; i < StoryData.length; i++) {
      let obj = StoryData[i];

      if (obj.url === id) {
        setUrl(obj.url);
        setPage(obj);
        setArtefact(obj);
      }
    }
  }, [id]);

  return (
    <>
      {!url ? (
        <div className="error-page">
          <Container>
            <Transition>
              <h1 className="error-page__title">404</h1>
              <p className="error-page__text">
                Oh no, this page doesn't seem to exist.
              </p>
              <ButtonPrimary buttonlink="/" buttonlabel="Back to home" />
              <div className="error-page__image">
                <img src={Error404} alt="404 page" />
              </div>
            </Transition>
          </Container>
        </div>
      ) : (
        <section className="story-pages">
          <Transition>
            <Container>
              <header></header>
              <Row>
                <Col md={6} lg={8}>
                  <div
                    className="story-pages__images"
                    style={loaded ? {} : { display: "none" }}
                  >
                    {page?.image1 && (
                      <div className="story-pages__image">
                        <img
                          width="100%"
                          height="100%"
                          src={page.image1}
                          alt={page.alttext}
                          onLoad={() => setLoaded(true)}
                        />
                      </div>
                    )}
                    {page?.image2 && (
                      <div className="story-pages__image">
                        <img
                          width="100%"
                          height="100%"
                          src={page.image2}
                          alt=""
                          onLoad={() => setLoaded(true)}
                        />
                      </div>
                    )}
                    {page?.image3 && (
                      <div className="story-pages__image">
                        <img
                          width="100%"
                          height="100%"
                          src={page.image3}
                          alt=""
                          onLoad={() => setLoaded(true)}
                        />
                      </div>
                    )}
                  </div>
                  {width <= 767 && (
                    <>
                      {page?.audio && (
                        <div className="story-pages__audio">
                          <Audio
                            audioSrc={page.audio}
                            audioInfo={page.audioinfo}
                          />
                        </div>
                      )}
                      {page?.text && (
                        <div className="story-pages__main-text">
                          <p>{page.text}</p>
                        </div>
                      )}
                    </>
                  )}
                  <div className="story-pages__question-artefact-content">
                    <div className="story-pages__question">
                      {page?.question && (
                        <p>{page.question}</p>
                      )}
                    </div>
                    {(artefact?.artefacttitle ||
                      artefact?.artefactpdf ||
                      artefact?.artefactimage ||
                      artefact?.artefactimagealt ||
                      artefact?.artefactimageinfo ||
                      artefact?.artefactimage2 ||
                      artefact?.artefactimagealt2 ||
                      artefact?.artefactimageinfo2 ||
                      artefact?.artefactimage3 ||
                      artefact?.artefactimagealt3 ||
                      artefact?.artefactimageinfo3 ||
                      artefact?.artefacttextorg ||
                      artefact?.artefacttexttrans ||
                      artefact?.artefacttextinfo ||
                      artefact?.artefactmp3 ||
                      artefact?.artefactmp3info ||
                      artefact?.artefactmp3desc ||
                      artefact?.artefactmp32 ||
                      artefact?.artefactmp3info2 ||
                      artefact?.artefactmp3desc2 ||
                      artefact?.artefactmp4 ||
                      artefact?.artefactmp4info ||
                      artefact?.artefactmp42 ||
                      artefact?.artefactmp4info2 ||
                      artefact?.artefactmp43 ||
                      artefact?.artefactmp4info3 ||
                      artefact?.artefactiframe ||
                      artefact?.artefactiframeinfo) && (
                      <div className="story-pages__artefact-modal">
                        <Button
                          onClick={() => setModalShow(true)}
                          variant="link"
                          aria-label="Button to open the artefact modal"
                        >
                          <IconMagnify />
                          <div>Artefacts</div>
                        </Button>
                        <ArtefactModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          artefact={artefact}
                        />
                      </div>
                    )}
                  </div>
                  <div className="story-pages__buttons">
                    {page?.ctatarget1 && (
                      <ButtonPrimary
                        buttonlink={page.ctatarget1}
                        buttonlabel={page.ctalabel1}
                        classes="btn-cta-1"
                      />
                    )}
                    {page?.ctatarget2 && (
                      <ButtonPrimary
                        buttonlink={page.ctatarget2}
                        buttonlabel={page.ctalabel2}
                        classes="btn-cta-2"
                      />
                    )}
                  </div>
                </Col>
                {width >= 768 && (
                  <Col md={6} lg={4}>
                    {page?.audio && (
                      <div className="story-pages__audio">
                        <Audio
                          audioSrc={page.audio}
                          audioInfo={page.audioinfo}
                        />
                      </div>
                    )}
                    {page?.text && (
                      <div className="story-pages__main-text">
                        {!page.text.map ? (
                          <p>{page.text}</p>
                        ) : (
                          page.text.map((paragraph, i) => (
                            <p key={i}>{paragraph}</p>
                          ))
                        )}
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            </Container>
          </Transition>
        </section>
      )}
    </>
  );
}

export default StoryPages;
