import React from "react";
import Magnifier from "react-magnifier";

function Image(props) {
    const { imageSrc, imageAlt, imageInfo} = props;
    return (
        <div className="image-component">
            <Magnifier
                src={imageSrc}
                alt={imageAlt}
                zoomFactor={2}
                mgWidth={200}
                mgHeight={200}
            />
            {imageAlt && (
                <p className="caption-text">{imageAlt}</p>
            )}
            {imageInfo && (
                <div>
                    {!imageInfo.map ? (
                        <p className="description-text" dangerouslySetInnerHTML={{ __html: imageInfo }}></p>
                    ) : (
                        imageInfo.map((paragraph, i) => (
                            <p className="description-text" key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                        ))
                    )}
                </div>
            )}
        </div>
    );
}

export default Image;
