import React, { useEffect } from "react";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "../Analytics/Analytics";

function CookieConsentBanner() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsent
      buttonText="Accept all"
      declineButtonText="Decline all"
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      buttonClasses="btn btn-primary btn-light"
      declineButtonClasses="btn btn-primary btn-light"
      disableButtonStyles
      expires={90}
      containerClasses="cookie-consent"
      buttonWrapperClasses="cookie-consent__buttons"
      style={{ alignItems: "center", background: "#3277b3", padding: "32px" }}
      buttonStyle={{ marginLeft: "20px" }}
    >
      <p>
        This website is capable of using cookies to improve your experience and
        collect analytical data.
        <br />
        Please indicate your preferences.
      </p>
    </CookieConsent>
  );
}

export default CookieConsentBanner;
