import React from "react";

function Audio(props) {
  const { audioDesc, audioSrc, audioInfo } = props;
  return (
    <div className="audio-component">
      <div className="audio-component__inner">
        {audioDesc && <p dangerouslySetInnerHTML={{ __html: audioDesc }}></p>}
        <audio controls>
          <source src={audioSrc} type="audio/ogg" />
          <source src={audioSrc} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      </div>
      {audioInfo && (
        <div>
          {!audioInfo.map ? (
            <p dangerouslySetInnerHTML={{ __html: audioInfo }}></p>
          ) : (
            audioInfo.map((paragraph, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default Audio;
